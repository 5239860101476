import {Button, type ButtonProps} from '@primer/react'
import {Suspense, useCallback, useMemo, useState} from 'react'

import {CreateIssueDialogEntryInternal, type CreateIssueDialogEntryProps} from './dialog/CreateIssueDialogEntry'
import {CreateIssueButtonLoading} from './CreateIssueButtonLoading'
import {loginPath} from './utils/urls'
import {GlobalCommands} from '@github-ui/ui-commands'
import {useNavigate} from '@github-ui/use-navigate'
import {isLoggedIn} from '@github-ui/client-env'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {CreateIssueDialogEntryInternalV2} from './dialog/CreateIssueDialogEntryV2'
import {noop} from '@github-ui/noop'

export type CreateIssueButtonProps = {
  label: string
  size?: 'small' | 'medium'
  withTemplates?: boolean
  isDialogOpenByDefault?: boolean
} & Omit<CreateIssueDialogEntryProps, 'setIsCreateDialogOpen' | 'isCreateDialogOpen'>

export const CreateIssueButton = ({
  label,
  size = 'medium',
  withTemplates = true,
  ...props
}: CreateIssueButtonProps): JSX.Element | null => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isNavigatingToNew, setIsNavigatingToNew] = useState(false)
  const navigate = useNavigate()
  const isViewerLoggedIn = isLoggedIn()
  const use_new_create_issue = isFeatureEnabled('issues_react_use_new_create_issue')

  const openDialog = useCallback(() => {
    if (isViewerLoggedIn) {
      setIsCreateDialogOpen(true)
    } else {
      navigate(loginPath())
    }
  }, [isViewerLoggedIn, navigate])

  const onCreateIssueShortcutClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      openDialog()
    },
    [openDialog],
  )

  const renderedButton = useMemo(() => {
    if (isNavigatingToNew) {
      return <CreateIssueButtonLoading label={label} size={size} />
    }
    const onlyRedirect = (props?.optionConfig?.navigateToFullScreenOnTemplateChoice && !withTemplates) || false
    return (
      <>
        {isViewerLoggedIn && <GlobalCommands commands={{'issue-create:new': openDialog}} />}
        <RenderedCreateButton
          size={size}
          label={label}
          onClick={!onlyRedirect ? onCreateIssueShortcutClick : noop}
          optionConfig={props.optionConfig}
          onlyRedirect={onlyRedirect}
        />
      </>
    )
  }, [
    isNavigatingToNew,
    props.optionConfig,
    withTemplates,
    isViewerLoggedIn,
    openDialog,
    size,
    label,
    onCreateIssueShortcutClick,
  ])

  if (!isCreateDialogOpen) return renderedButton

  const dialogProps = {
    isCreateDialogOpen,
    setIsCreateDialogOpen,
    setIsNavigatingToNew,
    canBypassTemplateSelection: true,
    ...props,
  }

  return (
    <Suspense fallback={<CreateIssueButtonLoading label={label} size={size} />}>
      {renderedButton}
      {use_new_create_issue ? (
        <CreateIssueDialogEntryInternalV2 {...dialogProps} />
      ) : (
        <CreateIssueDialogEntryInternal {...dialogProps} />
      )}
    </Suspense>
  )
}

type RenderedCreateButtonProps = {
  label: string
  size?: 'small' | 'medium'
  onClick: (e: React.MouseEvent) => void
  onlyRedirect: boolean
} & Pick<CreateIssueButtonProps, 'optionConfig'>

const RenderedCreateButton = ({size, label, onClick, optionConfig, onlyRedirect}: RenderedCreateButtonProps) => {
  const buttonHrefProps = useMemo(() => {
    if (!isLoggedIn()) {
      return {
        as: 'a',
        href: loginPath(),
        target: '_blank',
      } as Partial<ButtonProps>
    } else if (optionConfig?.scopedRepository) {
      return {
        as: 'a',
        href: `/${optionConfig.scopedRepository.owner}/${optionConfig.scopedRepository.name}/issues/${
          onlyRedirect ? 'new' : 'new/choose'
        }`,
        target: onlyRedirect ? '_self' : '_blank',
      } as Partial<ButtonProps>
    } else {
      // on issue#dashboard there is no href - when we click on the button it's a no-op
      // until React is fully loaded and we can render the issue create dialog
      return {}
    }
  }, [optionConfig?.scopedRepository, onlyRedirect])

  // we want to default to the underlying anchor functionality when the user is holding down the cmd or ctrl key
  // and therefore ignore the custom onClick functionality - expect on issue#dashboard where we always want to
  // open the dialog
  const ignoreOnClickIfCmdOrCtrlPressed = (e: React.MouseEvent, clickHandler: (e: React.MouseEvent) => void) => {
    const isIssueDashboard = !optionConfig?.scopedRepository
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if ((!e.ctrlKey && !e.metaKey) || isIssueDashboard) {
      clickHandler(e)
    }

    // ..bubble down to the underlying anchor functionality
  }

  return (
    <Button
      size={size}
      variant={'primary'}
      onClick={(e: React.MouseEvent) => ignoreOnClickIfCmdOrCtrlPressed(e, onClick)}
      {...buttonHrefProps}
    >
      {label}
    </Button>
  )
}

try{ CreateIssueButton.displayName ||= 'CreateIssueButton' } catch {}
try{ RenderedCreateButton.displayName ||= 'RenderedCreateButton' } catch {}